import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private loginService: LoginService,
      private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

      const url: string = state.url;

      return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {
      if (this.loginService.isLoggedIn()) {return true; }

      // store the attempted url for redirecting
      this.loginService.redirectUrl = url;

      // Navigate to the login page with extras
      this.router.navigate(['/login']);
      return false;
}
}
