import { Component, ElementRef, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormsModule } from '@angular/forms';
import { CommonModule, DOCUMENT } from '@angular/common';
import { NgbTypeaheadModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';



import { Member } from '../types/member';
import { CheckInResult } from '../types/checkInTypes';
import { CheckinClass } from '../types/checkinClass';
import { DataService } from '../services/data.service';
import { AppToastService } from '../services/app-toast.service';

interface CheckboxInput {
  id: string;
  labelName: string;
  labelDescription: string;
  labelTime: string;
  labelCurriculum: string;
  checked: boolean;
  teaching: boolean;
  checkinClass: CheckinClass;
}

type CheckinDisplayData = {
  curriculumName: string,
  milestone: string,
  classCount: string,
  currentCheckins: number,
  classesDone: boolean,
  eligibleDate: string,
  timeDone: boolean,
  successMessages: Array<string>,
  warningMessages: Array<string>,
  failMessages: Array<string>,
}



@Component({
  selector: 'app-checkin',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgbTypeaheadModule,
  ],
  providers: [
    DataService,
    NgbModal
  ],
  templateUrl: './checkin.component.html',
  styleUrl: './checkin.component.scss'
})
export class CheckinComponent implements OnInit {
  
  memberInput: string = '';
  members: Map<String, Member> = new Map<String, Member>();
  membersAutocomplete: any[] = [];
  recentMembers: Member[] = [];
  validMember: Member | undefined = undefined;

  inputControl = new FormControl();

  classCheckBoxes: CheckboxInput[] = [];

  selectedClasses: Map<String, CheckinClass> = new Map<String, CheckinClass>();
  results: CheckinDisplayData[] = [];
  successMessage: string = '';

  @ViewChild('studentInput') studentInput!: ElementRef;

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    private dataService: DataService, 
    private toastService: AppToastService,
    private modalService: NgbModal,
  ) { }

  @ViewChild('resultModal') resultModal: TemplateRef<any> | undefined;

  ngOnInit() {
    //TODO: Consider if we can remove the local Members list and just make requests to the data
    // service as needed

    // Subscribe to Member Data
    this.dataService.getMembersSubject().subscribe(m => {
      this.members = m
      this.membersAutocomplete = Array.from(this.members.keys())
    });
    
    this.dataService.getClassesToShowSubject().subscribe(c => {this.processClasses(c)})
    
    this.dataService.getRecentCheckinsSubject().subscribe(r => {this.recentMembers = r})
  }

  isTouchDevice() {
    return "ontouchstart" in window || navigator.maxTouchPoints;
  }

  searchMembers: OperatorFunction<string, readonly string[]> = (text$: Observable<string>)  =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) => 
        term.length < 2 ? [] : this.membersAutocomplete.filter((v) => v.toLowerCase().includes(term.toLowerCase())).slice(0,10),
      ),
    );

  private processClasses(classes: Array<CheckinClass>) {
    let classCheckboxes: Array<CheckboxInput> = classes.map((c, i) => {
      let cb: CheckboxInput = {
        id: "class-" + i,
        labelTime: `${c.location ? c.location + ' - ' : ''}${this.timeRangeString(c)}`,
        labelName: c.description,
        labelDescription: c.details,
        labelCurriculum: c.curriculumName,
        checked: false,
        teaching: false,
        checkinClass: c,
      };
      return cb;
    });
    this.classCheckBoxes = classCheckboxes;
  }

  private timeRangeString(c: CheckinClass): string {
    return `${c.startTimeString()} - ${c.endTimeString()}`;
  }

  classSelectionClicked(cb: CheckboxInput) {
    if (cb.checked) {
      this.selectedClasses.set(cb.id, cb.checkinClass);
    } else {
      this.selectedClasses.delete(cb.id);
    }
  }

  checkin() {
    //if the user is not in the list of members, reject and warn user.
    if (!this.members.has(this.memberInput.toLowerCase())) {
      alert('That name is not a member.  Try again!');
      return;
    }

    let member = this.members.get(this.memberInput.toLowerCase())
    if (member === undefined) {
      console.error("member value undefined")
      alert('Something unexpected happened.  Try again or contact support.')
      return
    }

    let classes = Array.from(this.selectedClasses.values())

    this.dataService.checkIn(member, classes, "student").then(responses => {
      
      let resultMap = new Map<string, any>()

      responses.forEach(response => {
        const curriculum = response.result.activity ? response.result.activity.curriculumID : ""
        const classesDone = response.result.processingResult.classesProgress >=  response.result.processingResult.classesTarget;
        const timeDone = new Date() >= response.result.processingResult.eligibleDate;
        const milestoneCompleted = classesDone && timeDone;
        
        let result: CheckinDisplayData
        
        // Retrieve a result from the map if one exists and update it.
        if (resultMap.has(curriculum)) {
          result = resultMap.get(curriculum);
          
          if (!response.result.processingResult.alertMessage) {
            result.curriculumName = response.result.processingResult.curriculumName;
            result.milestone = response.result.processingResult.milestoneName;
            result.classCount = this.makeClassCountString(response.result.processingResult);
            result.currentCheckins++;
            result.classesDone = classesDone;
            result.eligibleDate = this.makeEligibleDateString(response.result.processingResult.eligibleDate);
            result.timeDone = timeDone;
            result.successMessages = [this.makeSuccessMessage(result.currentCheckins, milestoneCompleted)];
            result.warningMessages = [];
            if (response.result.processingResult.warningMessage) {
              result.warningMessages.push(response.result.processingResult.warningMessage);
            }

          }
          if (response.result.processingResult.alertMessage) {
            result.failMessages.push(response.result.processingResult.alertMessage);
          }
        } else if (! response.result.processingResult.alertMessage) {
          // no existing existing milestone, and there is no alert message

          // TODO: Deal with Alert Messages and failed Checkins.
          result = {
            curriculumName: response.result.processingResult.curriculumName,
            milestone: response.result.processingResult.milestoneName,
            classCount: this.makeClassCountString(response.result.processingResult),
            currentCheckins: 1,
            classesDone: classesDone,
            eligibleDate: this.makeEligibleDateString(new Date(response.result.processingResult.eligibleDate)),
            timeDone: timeDone,
            successMessages: [this.makeSuccessMessage(1, milestoneCompleted)],
            warningMessages: [],
            failMessages: response.result.processingResult.alertMessage ? [response.result.processingResult.alertMessage] : [],
          }
          if (response.result.processingResult.warningMessage) {
            result.warningMessages.push(response.result.processingResult.warningMessage);
          }
        } else {
          // new entry with JUST an alert message.
          result = {
            curriculumName: response.result.processingResult.curriculumName,
            milestone: '',
            classCount: '',
            currentCheckins: 0,
            classesDone: false,
            eligibleDate: '',
            timeDone: false,
            successMessages: [],
            warningMessages: [],
            failMessages: [response.result.processingResult.alertMessage || 'Checkin Failure - contact support.'],
          }
        }

        resultMap.set(curriculum, result)
      })

      this.results = Array.from(resultMap.values())

      this.openModal()

      // add the user to the top of the recents list
      this.addUserToRecents(this.memberInput.toLowerCase())

      // clear the input
      this.memberInput = '';
      this.validMember = undefined;
      this.classCheckBoxes.forEach(checkbox => checkbox.checked = false);
      this.selectedClasses.clear()
    })
    .catch(e => {
      console.error(e);
      this.errorToast('Internal Error', 'Please try again or contact support')
    })
  }

  private makeClassCountString(result: CheckInResult): string {
    const achieved = result.classesProgress > result.classesTarget ? result.classesTarget : result.classesProgress
    let m = `${ achieved } of ${ result.classesTarget} Classes`
    return m
  }

  private makeSuccessMessage(totalClasses: number, milestoneCompleted: boolean): string {
    let m = `Successfuly checked in to ${ totalClasses } class${ totalClasses > 1 ? 'es' : '' }.`
    if (milestoneCompleted) {
      m += ' Talk to your teacher: you\'ve met your goal!';
    }

    return m
  }

  private makeEligibleDateString(date: Date): string {
    let d = '';
    if (date) {
      d = date.toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"});
    }

    return d
  }

  private openModal() {
    this.modalService.open(this.resultModal, { 
      fullscreen: 'lg',
      scrollable: true,
      size: 'xl',
      centered: true,
    });
  }

  private closeModal() {
    this.modalService.dismissAll();
  }

  private successToast(header: string, body: string) {
    this.toastService.show({
      header: header,
      body: body,
      classname: 'bg-success text-light',
      delay: 5000
    })
  }

  private errorToast(header: string, body: string) {
    this.toastService.show({
      header: header,
      body: body,
      classname: 'bg-danger text-light',
    })
  }

  private addUserToRecents(name: string) {
    // remove the member from the recents list if it is in there (to move it to the top)
    let found = false;
    for (let i = 0; i < this.recentMembers.length && !found; i++) {
      if (this.recentMembers[i].loginName === name) {
        this.recentMembers.splice(i, 1);
        found = true;
      }
    }
    
    // Add the member to recent members at the top
    let m = this.members.get(name);
    if (m === undefined) {
      // This should be an Error condition that gets logged because this assumes the member exists.
      alert('That name is not a member.  Try again!');
      return;
    }
    m.lastCheckIn = new Date();
    
    // Insert the member at the start of the list.
    this.recentMembers.unshift(m);
  }

  selectRecentMember(member: Member) {
    this.memberInput = member.loginName;
    this.checkMember();
  }

  typeAheadChanged(event: any) {
    this.memberInput = event.item
    if (this.studentInput) this.studentInput.nativeElement.blur();
    this.checkMember();
  }
  
  nameChanged(newMember: any) {
    this.memberInput = newMember
    this.checkMember();
  }

  checkMember(){
    if (this.members.has(this.memberInput.toLowerCase())) {
      this.validMember = this.members.get(this.memberInput.toLowerCase());
    } else {
      this.validMember = undefined;
    }
  }

  trackByLen(index: number, student: string): number {
    return (this.members ? this.members.size : 0);
  }


}
