import { Component } from '@angular/core';
import { NgIf } from '@angular/common';

import { UpdatesNotificationService } from './../services/updates-notification.service';


@Component({
    selector: 'updates-notification',
    standalone: true,
    imports: [NgIf],
    templateUrl: './updates-notification.component.html',
    styleUrls: ['./updates-notification.component.scss'],
})
export class UpdatesNotificationComponent {

    constructor(
      public updates: UpdatesNotificationService
    ) { }

}