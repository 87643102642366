<div class="container">
  <div class="row">
      <div class="col-sm-8">
          <h1>Log in to the Dojo-Checkin app</h1>

          <form #f="ngForm" (ngSubmit)="f.valid && login(f.value.loginName, f.value.password)" novalidate>

              <div class="form-group" [class]="{ 'has-error': f.submitted && !loginName.valid }">
                  <label for="loginName">Login Name (Not Email!!!)</label>
                  <input type="text" class="form-control" name="loginName" [(ngModel)]="model.loginName" #loginName="ngModel" required />
                  <div *ngIf="f.submitted && !loginName.valid" class="help-block">Login Name is required</div>
              </div>
                  
              <div class="form-group" [class]="{ 'has-error': f.submitted && !password.valid }">
                  <label for="password">Password</label>
                  <input type="password" class="form-control" name="password" [(ngModel)]="model.password" #password="ngModel" required />
                  <div *ngIf="f.submitted && !password.valid" class="help-block">Password is required</div>
              </div>

              <div class="form-group">
                  <button [disabled]="loading" class="btn btn-primary">Login</button>
                  <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              </div>
              <div class="form-group">
                  <p>Need an account? <a routerLink="/register">Sign-up here</a></p>
              </div>
              
              <div *ngIf="message" class="alert alert-secondary">{{message}}</div>
              <div *ngIf="error" class="alert alert-danger">{{error}}</div>
          </form>
      </div>
  </div>
</div>