import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { LoginService } from '../services/login.service';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    FormsModule,
    NgIf
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  constructor(
    public router: Router, 
    private titleService: Title, 
    private loginService: LoginService) {}
  // login() {
  //   this.router.navigate(['/checkin']);
  // }


  model: any      = {};
  loading         = false;
  error: string   = '';
  message: string = '';


  ngOnInit() {
      this.titleService.setTitle("Login | Dojo Checkin App")
  }

  setMessage() {
      this.message = 'Logged ' + (this.loginService.isLoggedIn()) ? 'in' : 'out';
  }

  login(loginName: String, userPassword: String) {
      this.message = 'Trying to log in ...';
      this.loading = true;

      // basically, every time the serverService Observable emits a value, set the message and redirct if logged in.
      this.loginService.login(loginName, userPassword).subscribe(
          () => {
              this.setMessage();
              if (this.loginService.isLoggedIn()){

                  // // set redirect from Auth Service to a redirect URL if it exists, or the root of it doesn't
                  // const redirect = this.loginService.redirectUrl ? this.loginService.redirectUrl : '/';

                  // redirect the user
                  this.router.navigate(['/checkin']);
              } else {
                  // any time a new value comes and it is false (login session ended), redirect back to login screen
                  this.message = 'Session expired.  Please log in again';
                  this.router.navigate(['/login']);
              }
          },

          // error function
          (err: Error) => {
              this.error      = 'Login failed with Error: ' + err;
              this.message    = '';
          }
      );

      this.loading = false;
  }

  logout() {
      this.loginService.logout();
      this.setMessage();
  }

}
