

<main class="main">
  <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
  <div class="app-header align-items-center">
    <div class="row d-flex align-items-center">
      <div class="col-auto">
        <button class="btn btn-sm btn-success" [hidden]="!isLoggedIn()" (click)="refresh()">Refresh</button>
      </div>
      <div class="col align-items-center text-center">
        <span class="fs-4">
          <b>Welcome! Please Check In</b>
        </span>
      </div>
      <div class="col-auto">
        <button class="btn btn-sm btn-warning" [hidden]="!isLoggedIn()" (click)="openLogoutModal()">Log Out</button>
      </div>
    </div>
  </div>

  <div class="content container-fluid p-0">
    <router-outlet />
  </div>
  <updates-notification></updates-notification>


  <!-- <div class="app-footer">
    Footer!
  </div> -->
</main>

<ng-template #logoutModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Log Out?</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <!-- <span aria-hidden="true">&times;</span> -->
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col">
        <span class="h6">Please confirm you want to log out</span>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close('Close click')">Cancel</button>
    <button type="button" class="btn btn-warning" (click)="logout()">Logout</button>
  </div>
</ng-template>