export class CheckinClass {
  start: {
    hour: number;
    min: number;
  };
  end: {
    hour: number;
    min: number;
  }
  location: string;
  description: string;
  details: string;
  curriculumID: string;
  curriculumName: string;
  canCheckIn: boolean;
  
  constructor(start: {hour:number,min:number}, end: {hour:number,min:number}, location: string, description: string, details: string, curriculumID: string, curriculumName: string, canCheckIn: boolean);
  constructor(checkinClass: CheckinClass);
  constructor(startOrCheckinClass: {hour:number,min:number} | CheckinClass, end?: {hour:number,min:number}, location?: string, description?: string, details?: string, curriculumID?: string, curriculumName?: string, canCheckIn?: boolean) {
    if (typeof startOrCheckinClass === 'object' && startOrCheckinClass.hasOwnProperty('start')) {
      startOrCheckinClass = startOrCheckinClass as CheckinClass;
      this.start = startOrCheckinClass.start;
      this.end = startOrCheckinClass.end;
      this.location = startOrCheckinClass.location;
      this.description = startOrCheckinClass.description;
      this.details = startOrCheckinClass.details;
      this.curriculumID = startOrCheckinClass.curriculumID;
      this.curriculumName = startOrCheckinClass.curriculumName;
      this.canCheckIn = startOrCheckinClass.canCheckIn;
    } else {
      startOrCheckinClass = startOrCheckinClass as {hour: number, min:number}
      this.start = startOrCheckinClass;
      this.end = end as {hour: number, min:number};
      this.location = location as string;
      this.description = description as string;
      this.details = details as string;
      this.curriculumID = curriculumID as string;
      this.curriculumName = curriculumName as string;
      this.canCheckIn = canCheckIn as boolean;
    }
  }

  public startTime(): Date {
    let startTimeToday = new Date();
    startTimeToday.setHours(this.start.hour, this.start.min,0,0)
    return startTimeToday;
  }

  public endTime(): Date {
    let endTimeToday = new Date();
    endTimeToday.setHours(this.end.hour, this.end.min,0,0)
    return endTimeToday;
  }

  static compare(a: CheckinClass, b: CheckinClass) {
    // sort by null/undefined first
    if (!a){
      return 1;
    } else if (!b){
      return -1;
    } else if(!a && !b) {
      return 0;
    } else {
      // sort by location first, then start hour then start minute
      if (a.location > b.location) {
        return -1
      } else if (a.location < b.location) {
        return 1;
      } else {
        if (a.start === null &&  b.start !== null){
          return 1;
        } else if (a.start !== null && b.start === null){
          return -1;
        } else if (a.start === null &&  b.start === null ){
          return 0;
        } else {
          if (a.start.hour < b.start.hour) {
            return -1;
          } else if (a.start.hour > b.start.hour) {
            return 1;
          } else {
            if (a.start.min < b.start.min) {
              return -1;
            } else if (a.start.min > b.start.min) {
              return 1;
            } else {
              return 0;
            }
          }
        }
      }
    }
  }

  // description generates a descritive string for the class that is user readable
  public detailedDescription(): string {
    return `${this.startTimeString()}${this.location ? ' in ' + this.location : ''}${this.curriculumName ? ', '+this.curriculumName : ''}${this.description ? ', '+this.description : ''}${this.details ? ', '+this.details : ''}`
  }

  public startTimeString(): string {
    return this.timeString(this.start);
  }

  public endTimeString(): string {
    return this.timeString(this.end);
  }

  private timeString(time: {hour: number, min:number}): string {
    let meridian = time.hour >= 12 ? "p" : "a"
    let hour = time.hour % 12;
    if (hour === 0) {
      hour = 12;
    }
    return `${(hour + "")}:${(time.min + "").padStart(2, "0")}${meridian}`;
  }

}

