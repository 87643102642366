import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { LoginService } from './services/login.service';
import { AppToastService } from './services/app-toast.service';
import { AppToastsComponent } from './app-toasts/app-toasts.component';
import { UpdatesNotificationService } from './services/updates-notification.service';
import { UpdatesNotificationComponent } from './updates-notification/updates-notification.component';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    AppToastsComponent,
    UpdatesNotificationComponent,
  ],
  providers: [
    AppToastService,
    NgbModal,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'Dojo Check-in';

  constructor (
    private loginService: LoginService,
    private router: Router,
    private modalService: NgbModal,
    private updates: UpdatesNotificationService,
    ) { }
  
  @ViewChild('logoutModal') logoutModal: TemplateRef<any> | undefined;


  logout() {
    this.loginService.logout();
    this.modalService.dismissAll();
    this.router.navigate(['/login']);
    
  }

  openLogoutModal() {
    this.modalService.open(this.logoutModal, { 
      size: 'sm',
      centered: true,
    });
  }
  

  refresh() {
    window.location.reload();
  }

  isLoggedIn() {
    return this.loginService.isLoggedIn();
  }

  isUpdateAvailable(): boolean {
    return this.updates.isNavVisible()
  }

  updateApp() {
      this.updates.activateUpdate();
  }
}
