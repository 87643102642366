<div class="checkin-main container-fluid">
  <div class="row h-100">
    
    <!-- LEFT COLUMN -->
    <div class="left-col col-6 col-lg-5 border">
      
      <!-- LEFT-TOP SECTION -->
      <div class="left-top">
        <h2>
          Student Name
          
        </h2>

        <ng-template #rt let-r="result" let-t="term">
          <span class="fs-4">{{ r }}</span>
        </ng-template>

        <div class="container pr-0 border">
          <div class="row pr-0" >
            <div class="p-0" [ngClass]="{'col-12': !validMember, 'col-9 col-sm-10 col-lg-11': validMember}">
              <input #studentInput class="fs-3 student-input border-0"
                autocorrect="off" autocapitalize="none" autocomplete="off"
                [(ngModel)]="memberInput" (input)="nameChanged(studentInput.value)"
                loginName="studentInput" type="text" id="student-input" 
                placeholder="Enter or select a member name"
                [ngbTypeahead]="searchMembers"
                [resultTemplate]="rt"
                (selectItem)="typeAheadChanged($event)"
              >
            </div>
            <div class="px-auto" [ngClass]="{'col-0': !validMember, 'col-3 col-sm-2 col-lg-1 pt-2': validMember}" style="background: white;" >
              <i *ngIf="validMember"
                class="fa-regular fa-circle-check fs-3" 
                style="color: #63E6BE; background: white"></i>
            </div>
          </div>
        </div>
      </div>
      
      <!-- LEFT-BOTTOM SECTION -->
      <div class="left-bottom mt-2">
        <div class="checkedin-list-container">
          <div class="checkedin-list-title">
            <h2>Recent Check-ins</h2>
          </div>
          
          <div class="checkedin-list ">
            
              <div class="mx-1" *ngFor="let member of recentMembers"
                (click)="selectRecentMember(member)">
                <div class="row mx-1 rounded-2" [ngClass]="{'bg-info-subtle': member.loginName === memberInput}">

                  
                  <div class="col fs-3">{{ member.loginName }} </div>
                  <div class="col float-end ">
                    <span class="fs-5 float-end">{{ member.lastCheckIn ? member.lastCheckIn.toLocaleDateString() : 'Never' }}</span>
                  </div>
                </div>
              </div>
            
          </div>
        </div>
      </div>
    </div>

    <!-- RIGHT COLUMN -->
    <div class="right-col col-6 col-lg-7 border">
      
      <!-- RIGHT-TOP SECTION -->
      <div class="right-top ">
        <div class="class-list-container">
          <div class="class-list-title">
            <span class="h2">Classes</span>
          </div>
          
          <div class="class-list">
            <div *ngIf="classCheckBoxes.length === 0" class="m-2">
              <h3 class="border border-3 border-danger rounded-2 text-center">No classes today!</h3>
            </div>
            <div class="m-2" *ngFor="let cb of classCheckBoxes">
              
              <input class="btn-check" type="checkbox" 
                [(ngModel)]="cb.checked" id="{{cb.id}}"
                (change)="classSelectionClicked(cb)">
              <label class="btn btn-outline-primary w-100" for="{{cb.id}}">
                <div class="row my-0">
                  <div class="col-8 my-0 text-start">
                    <strong><p class="fs-4 my-2">{{cb.labelCurriculum}}</p></strong>
                  </div>
                  <div class="col-4 my-0 text-end border-1 border-start">
                    <p class="fs-4 my-2">{{ cb.labelTime }}</p>
                  </div>
                </div>
                <hr class="my-0">
                <div class="row my-0">
                  <div class="col-8 my-0 text-start">
                    <p class="fs-4 my-2">{{ cb.labelName }}</p>
                  </div>
                  <div class="col-4 my-0 text-end border-1 border-start">
                    <p class="fs-4 my-2">{{ cb.labelDescription }}</p>
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>

      <!-- RIGHT-BOTTOM SECTION -->
      <div class="right-bottom border" style="overflow-y: scroll;">
        <div class="checkin-button-container">
          <button type="submit" class="checkin-button btn btn-large btn-success" 
            (click)="checkin()" [disabled]="(!validMember) || selectedClasses.size < 1"><span class="fs-2">Check-in</span></button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #resultModal let-modal>
  <div class="modal-header">
    <!-- <h4 class="modal-title">Successful Check-In</h4> -->
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <!-- <span aria-hidden="true">&times;</span> -->
    </button>
  </div>
  <div class="modal-body d-flex flex-column align-items-center justify-content-center">
    <div style="width: 100%;">
      <div *ngFor="let result of results" class="row mx-0 mb-2">
        <div class="px-0 curriculum border border-2 border-black rounded-2 bg-primary-subtle">
          
          <!-- Title -->
          <div class="row mt-1">
            <div class="col">
              <p class="text-center">
                <span class="h3">Your <strong>{{ result.curriculumName }}</strong> Progress</span>
              </p>
            </div>
          </div>
          
          <!-- Body  -->
          <div *ngIf="result.currentCheckins > 0" class="row m-2">
            <div class="col-4 px-1">
              <div class="border border-2 border-black rounded-2" 
                [ngClass]="{ 'bg-light': !(result.classesDone && result.timeDone), 'bg-success': result.classesDone && result.timeDone, 'text-light': result.classesDone && result.timeDone }">

                <div class="row">
                  <div class="col text-center">
                    <span><small><u>Current Goal:</u></small></span>
                  </div>
                </div>
                <div class="row">
                  <div class="col text-center">
                    <span class="h4"><strong>{{result.milestone}}</strong></span>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="col-4 px-1">
              <div class="border border-2 border-black rounded-2"
              [ngClass]="{ 'bg-light': !(result.classesDone), 'bg-success': result.classesDone, 'text-light': result.classesDone }">

                <div class="row">
                  <div class="col text-center">
                    <span><small><u>Class Count:</u></small></span>
                  </div>
                </div>
                <div class="row">
                  <div class="col text-center">
                    <span class="h4"><strong>{{result.classCount}}</strong></span>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="col-4 px-1">
              <div class="border border-2 border-black rounded-2"
              [ngClass]="{ 'bg-light': !(result.timeDone), 'bg-success': result.timeDone, 'text-light': result.timeDone }">
                
                <div class="row">
                  <div class="col text-center">
                    <span><small><u>Eligible After:</u></small></span>
                  </div>
                </div>
                <div class="row">
                  <div class="col text-center">
                    <span class="h4"><strong>{{ result.eligibleDate }}</strong></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="result.currentCheckins === 0" class="row m-2">
            <div class="col px-1">
              <div class="border border-2 border-black rounded-2 bg-danger-subtle">
                <div class="row">
                  <div class="col text-center">
                    <p class="fs-4"><strong>No successful Checkins!</strong></p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Messages -->
          <div *ngIf="result.successMessages && result.successMessages.length > 0" class="row m-2">
            <div class="col px-1">
              <div class="border border-2 border-black rounded-2 bg-success-subtle">
                <div class="row">
                  <div class="col text-center">
                    <p class="fs-4" *ngFor="let message of result.successMessages">{{ message }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="result.warningMessages && result.warningMessages.length > 0" class="row m-2">
            <div class="col px-1">
              <div class="border border-2 border-black rounded-2 bg-warning-subtle">
                <div class="row">
                  <div class="col text-center">
                    <p class="fs-4" *ngFor="let message of result.warningMessages">{{ message }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="result.failMessages && result.failMessages.length > 0" class="row m-2">
            <div class="col px-1">
              <div class="border border-2 border-black rounded-2 bg-danger-subtle">
                <div class="row">
                  <div class="col text-center">
                    <p class="fs-4" *ngFor="let message of result.failMessages">{{ message }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close('Close click')">Close</button>
  </div>
</ng-template>
