import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject, defer, repeat, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import { Member } from '../types/member';
import { CheckinClass } from '../types/checkinClass';
import { LoginService } from './login.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CheckIn, CheckInResponse, ActivityResponse, Activity } from '../types/checkInTypes';

const UPDATE_INTERVAL = 15 * 60 * 1000; // 15 minutes in ms
const CLASS_CHECKIN_CUTOFF_MINUTES = 20; // minutes
const PRE_CLASS_CHECKIN_INTERVAL  = 3 * 60 * 60 * 1000; // 3 hours in ms

const RECENT_CHECKIN_LIST_ID = 'recent-checkin-list';

@Injectable({
  providedIn: null
})
export class DataService {

  private backendUrl = environment.apiUrl;

  private members: Map<string, Member> = new Map<string, Member>();

  private membersSubject: BehaviorSubject<Map<string, Member>> = new BehaviorSubject<Map<string, Member>>(new Map<string, Member>());
  private recentMembersSubject: BehaviorSubject<Array<Member>> = new BehaviorSubject<Array<Member>>(new Array<Member>());

  private classes: Array<Array<CheckinClass>> = new Array<Array<CheckinClass>>();
  private classesToShowSubject: BehaviorSubject<Array<CheckinClass>> = new BehaviorSubject<Array<CheckinClass>>(new Array<CheckinClass>())
  
  // Status values
  private startedLoading = false;
  private gotInitialUpdate = false;

  private checkinStartTime: number = Date.now();
  private checkinEndTime: number = Date.now();

  private recentCheckIns: Map<string, Date> = new Map<string, Date>();

  constructor(
    private loginService: LoginService,
    private http: HttpClient
  ) {

  }

  // getMembersSubject returns a BehaviourSubject that emits a Map of Members when updated.
  getMembersSubject(): BehaviorSubject<Map<string, Member>> {
    if ( this.startedLoading === false) {
      this.beginCollectingData();
    }
    return this.membersSubject;
  }
  
  // getClassesToShowSubject returns a BehaviourSubject that emits an array of CheckinClass objects
  // when updated.
  getClassesToShowSubject(): BehaviorSubject<Array<CheckinClass>> {
    if ( this.startedLoading === false) {
      this.beginCollectingData();
    }
    return this.classesToShowSubject;
  }

  // getRecentCheckinsSubject returns a BehaviourSubject that emits a Map recent members when updated.
  getRecentCheckinsSubject(): BehaviorSubject<Array<Member>> {
    if ( this.startedLoading === false) {
      this.beginCollectingData();
    }
    return this.recentMembersSubject;
  }

  // TODO: Store the Observable in a variable and provide the ability to unsubscribe and resubscribe
  // as a method (manual refresh from the client)

  private beginCollectingData(): void {
    this.startedLoading = true;
    defer(() => this.fetchData())
    .pipe(
      repeat({delay: 0, count:1}),
      repeat({ delay: UPDATE_INTERVAL })
    ).subscribe();
  }

  private async fetchData() {
    if (!this.loginService.isLoggedIn()) {
      console.log("Not logged in - no update needed...");
      return;
    }
    if (this.isDuringClassTime() || !this.gotInitialUpdate) {
      Promise.all([this.fetchMembers(), this.fetchClasses()])
      .then(() => {
        this.gotInitialUpdate = true;
      })
      .catch(err => {
        console.error("update failed: ", err)
      })
    } else {
      console.log("No update needed - outside class time...");
      return
    }
  }

  private isDuringClassTime(): boolean {
    const now = Date.now();
    return now >= this.checkinStartTime && now <= this.checkinEndTime;
  }

  private fetchClasses(): Promise<void> {
    const opts = {
      headers: new HttpHeaders()
    }

    let curricula = this.getCurricula()
    let promises: Array<Promise<Array<Array<CheckinClass>>>> = []

    curricula.forEach(c => {
      let url = `${this.backendUrl}/curricula/${c}/scheduleData`;
      promises.push(firstValueFrom<Array<Array<CheckinClass>>>(
        this.http.get<Array<Array<CheckinClass>>>(url, opts)
      ))
    })

    return Promise.all(promises)
    .then((lists: Array<Array<Array<CheckinClass>>>) => {
      let classes = new Array<Array<CheckinClass>>(7);
      for (let d=0; d<7; d++){
        classes[d] = new Array<CheckinClass>();
        lists.forEach((c: Array<Array<CheckinClass>>) => {
          c.forEach((row, r) => {
            if (c[r][d].start && c[r][d].canCheckIn){
              classes[d].push(new CheckinClass(c[r][d]));
            }
          })
        })
        classes[d].sort(CheckinClass.compare)
      }
      return classes;
    })
    .then(
      classes => {
        this.classes = classes;
        let weekday = new Date().getDay()  // date in local time
        this.processReceivedClasses(this.classes[weekday]);
        console.log("Classes Updated!")
      }
    )
  }

  private processReceivedClasses(classes: Array<CheckinClass>) {
    const now = new Date();
    let earliestClass = now.setHours(23, 59, 59, 999);
    let latestClass = now.setHours(0, 0, 0, 0);

    let cutoff =  new Date();
    cutoff.setMinutes(cutoff.getMinutes() - CLASS_CHECKIN_CUTOFF_MINUTES);
    let preClass = new Date();
    preClass.setMinutes(preClass.getMinutes() + PRE_CLASS_CHECKIN_INTERVAL);

    let activeClasses = classes.reduce((classes, c) => {
      // include the class if it started less than 20 minutes ago and later than 3 hours ago 
      if (c.startTime() > cutoff && c.startTime() < preClass ) {
        classes.push(c);
        if (c.startTime().getTime() < earliestClass) {
          earliestClass = c.startTime().getTime();
        }
        if (c.endTime().getTime() > latestClass) {
          latestClass = c.startTime().getTime();
        }
      }
      return classes;
    }, new Array<CheckinClass>())
    
    this.checkinStartTime = earliestClass - PRE_CLASS_CHECKIN_INTERVAL;
    this.checkinEndTime = latestClass + CLASS_CHECKIN_CUTOFF_MINUTES * 60 * 1000;
    this.classesToShowSubject.next(activeClasses);
  }

  private getCurricula(): String[] {
    return this.loginService.getCurriculumList();
  }

  private fetchMembers(): Promise<void> {
    const opts = {
        headers: new HttpHeaders()
    }

    let url = `${this.backendUrl}/users/checkin/?`;
    let curricula = this.getCurricula()
    curricula.forEach((c, i) => {
      url += `curricula=${c}`
      if (i < (curricula.length - 1)) {
        url += "&"
      }
    })

    return firstValueFrom<Map<string, Member>>(
      this.http.get<Member[]>(url, opts)
      .pipe(
        map((members: Member[]) => {
          let memberMap: Map<string, Member> = new Map<string, Member>();

          members.forEach((member) => {
            let m: Member = {
              id: member.id,
              loginName: member.loginName,
              lastCheckIn: typeof member.lastCheckIn === "number" ? new Date(member.lastCheckIn) : member.lastCheckIn,
              studentMilestones: member.studentMilestones,
              instructorMilestones: member.instructorMilestones,
              // isInstructor: member.isInstructor,
            }
            memberMap.set(m.loginName, m);
          })
          return memberMap;
        })
      )
    ).then(
      members => {
        this.members = members;
        this.membersSubject.next(this.members);
        this.recentMembersSubject.next(this.getRecentMembers());
        console.log("Members Updated!")
      }
    )
  }

  private getRecentMembers(): Member[] {

    let members: Member[] = []
    let i = 0;
    for (const val of this.members.values()) {
      members.push(val)
      i++
      if (i >= 20) {
        break
      }
    }
    return members
  }

  async checkIn(member: Member, classes: CheckinClass[], type: string): Promise<CheckInResponse[]> {
    let requests = new Array<() => Promise<any>>()
    let results: Array<ActivityResponse> = []
    this.refreshRecentCheckIns()
    
    classes.forEach(async (c) => {
      let checkInString = `${member.loginName} at ${c.detailedDescription()}`
      if (this.recentCheckIns.has(checkInString)){
        console.log(`User has already checked in to this class and is being rejected: ${checkInString}`)

        requests.push(() => Promise.resolve({
          activity: {
            curriculumID: c.curriculumID
          } as Activity,
          processingResult: {
            alertMessage: `You've already checked into the class: ${c.detailedDescription()}`,
            curriculumName: c.curriculumName,
            milestoneName: '',
            successMessage: '',
            classesTarget: 0,
            classesProgress: 0,
            eligibleDate: new Date(0),
            checkInRequest: {} as CheckIn,
          }
        } as ActivityResponse))
        return;
      }

      let payload: Activity = {
        activityType: "checkIn",
        userID: member.id,
        curriculumID: c.curriculumID,
        timestamp: new Date(),
        checkIn: {
          classDetails: c.detailedDescription(),
          checkInType: type,
          classTime: c.startTime(),
        },
      }

      requests.push(() => {
        return firstValueFrom(this.http.post<ActivityResponse>(this.backendUrl + "/activities", payload))
        .then((result: ActivityResponse) => {
          if (!(result.processingResult.alertMessage && result.processingResult.alertMessage.length > 0)) {
            this.addRecentCheckIn(checkInString)
          }
          result.processingResult.eligibleDate = new Date(result.processingResult.eligibleDate)
          results.push(result)
          return result
        })
      })
      return;
    })

    // Sequentially process checkin requests ensuring one completes before the next runs.
    let response: CheckInResponse[] = [];
    for (const f of requests) {
      const result = await f();
      
      response.push({
        member: member,
        result: result,
      } as CheckInResponse)
    }
    return response;
  }

  private refreshRecentCheckIns(){
    const retrieved = localStorage.getItem(RECENT_CHECKIN_LIST_ID) || "[]";
    let parsedArray: Array<Array<string>> = []
    
    try{
      parsedArray = JSON.parse(retrieved)
    } catch (e) {
      console.error("bad value from local storage for recentCheckIns")
    }
    
    const hour = 1000 * 60 * 60
    const expiry = new Date(Date.now() - (24 * hour));
    
    this.recentCheckIns = parsedArray.reduce((newMap, entry) => {
      const [key, dateString] = entry;
      const date = new Date(dateString);
      if (date > expiry ) {
        newMap.set(key, date)
      }

      return newMap
    }, new Map<string, Date>())

    // console.log('read raw data ', retrieved, ' converted to map ', this.recentCheckIns)
  }

  private addRecentCheckIn(checkInString: string) {
    this.recentCheckIns.set(checkInString, new Date());
    const serialized  = JSON.stringify(Array.from(this.recentCheckIns));
    // console.log('Storing map ', this.recentCheckIns, ' serialized as ', serialized)
    localStorage.setItem(RECENT_CHECKIN_LIST_ID, serialized);
  }
}

