import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/internal/operators/filter';

@Injectable({
    providedIn: 'root'
})
export class UpdatesNotificationService {
    available: boolean = false;
    closed: boolean = true;


    constructor(private updates: SwUpdate) {
        // this.updates.available.subscribe(event => {
        //     // this.updateAvailableSubject$.next(true)
        //     console.log("Update Available Event")
        //     this.available = true;
        //     this.closed = false;
        // })

        // this.updates.activated.subscribe(event => {
        //     // this.updateAvailableSubject$.next(false);
        //     console.log("Update Activated Event")
        //     this.available = false;
        //     this.closed = true;
        //     document.location.reload();
        // })
        updates.versionUpdates
        .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
        .subscribe(evt => {
            this.available = true;
            this.closed = false;
        });
    }

    activateUpdate() {
      this.updates.activateUpdate().then(() => {
        console.log("Activating update")
        this.closed = true;
        this.available = false;
        document.location.reload();
      });
        
    }

    isBannerVisible() {
        return this.available && !this.closed;
    }

    isNavVisible() {
        return this.available && this.closed;
    }

    close() {
        this.closed = true
    }
}
