import { ActivatedRouteSnapshot } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, catchError, map, ReplaySubject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

import { environment } from '../../environments/environment';


export interface AuthData{
  newJwt: String;
}

@Injectable()
export class LoginService {
  private backendUrl = environment.apiUrl;
  jwt: String = '';
  jwtDecoded: any;
  loginStateSubject: ReplaySubject<boolean>;
  curriculumList: String[] = [];

  // place to store a redirect URL so that we can redirect after login
  public redirectUrl = '';

  constructor(
    private httpClient: HttpClient,
    private jwtHelper: JwtHelperService
  ) { 
    this.loginStateSubject = new ReplaySubject<boolean>(1)
    this.loginStateSubject.next(false);

    if (this.isLoggedIn()){
      let token = localStorage.getItem('access_token') || "";
      this.processAuthenticationData({newJwt: token});
    }
  }

  login(loginName: String, userPassword: String): Observable<boolean> {

    const requestBody = {
        loginName: loginName,
        userPassword: userPassword
    };

    const opts = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        })
    }

    let processAuthCallback: {(arg0: AuthData): boolean} = this.processAuthenticationData.bind(this);

    return this.httpClient.post<AuthData>(this.backendUrl + '/authentications', requestBody, opts)
    .pipe(
        map(processAuthCallback),
        catchError(this.catchHttpError)
    );
  }

  logout(): void {
    this.jwt = '';
    this.jwtDecoded = {};
    localStorage.removeItem('access_token');
    this.loginStateSubject.next(false);
  }

  getCurriculumList(): String[] {
    return this.curriculumList
  }

  processAuthenticationData(authData: AuthData): boolean {
      this.jwt = authData.newJwt;
      this.jwtDecoded = this.decodeJwt(this.jwt);
      localStorage.setItem('access_token',this.jwt.toString());
      this.curriculumList = this.jwtDecoded.authorizations.checkInList
      if (! (this.curriculumList) || this.curriculumList.length < 1 ) {
        throw new Error("No curriculum found in JWT - Login failed")
      }
      this.loginStateSubject.next(true);
      return this.isLoggedIn();
  }

  isLoggedIn(): boolean {
    // if the jwt is set and non-blank and valid then return true
    let token = localStorage.getItem('access_token');

    if (token === null) return false;

    if (this.jwtHelper.isTokenExpired(token)){
        //clean up expired token
        localStorage.removeItem('access_token');
        return false;
    } else {
        return true;
    }
}

  decodeJwt(token: String): any{
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(window.atob(base64));
}

  //utility method to make HTTP Error handling more DRY
  catchHttpError(errorResponse: HttpErrorResponse){
    let message = 'Unknown Error';

    if (errorResponse.error instanceof ErrorEvent){
        // A Client Side or Network error occurred
        message = 'Sorry, something is broken on our end.  Please try again later or contact support.';
        console.error(errorResponse.error);
    } else if (errorResponse.error && (errorResponse.error.errorCode && errorResponse.error.errorMessage)){
        // Error Message generated by the app so display it
        message = errorResponse.error.errorMessage;
        console.error('Error from Api: '+ errorResponse.error.errorCode +': '+ errorResponse.error.errorMessage)
        console.error(errorResponse.error)
    } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        message = 'Sorry, something is broken on our end.  Please try again later or contact support.';
        console.error(errorResponse);            
    }
    return observableThrowError(() => message);
  }

}



