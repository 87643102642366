import { CheckinComponent } from './checkin/checkin.component';
import { LoginComponent } from './login/login.component';
import { Routes } from '@angular/router';
import { AuthGuard } from './services/auth-guard.service';

export const routes: Routes = [
    
  {
    path: "login", 
    component: LoginComponent
  },
  {
    path: "checkin", 
    component: CheckinComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**', 
    redirectTo: '/checkin', 
    pathMatch: 'full' 
  },
];
