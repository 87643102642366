import { ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
// import { provideClientHydration } from '@angular/platform-browser';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { LoginService } from './services/login.service';
import { JwtModule } from '@auth0/angular-jwt';
import { environment } from '../environments/environment';
import { DataService } from './services/data.service';
import { provideServiceWorker } from '@angular/service-worker';
import { UpdatesNotificationService } from './services/updates-notification.service';


function myTokenGetter() {
  return localStorage.getItem('access_token');
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes), 
    // provideClientHydration(),
    DataService,
    LoginService,
    UpdatesNotificationService,
    importProvidersFrom(
      JwtModule.forRoot({
        config: {
          tokenGetter: myTokenGetter,
          allowedDomains: [environment.apiHost]
        }
      })
    ),
    provideHttpClient(
      withInterceptorsFromDi()
    ),
    provideServiceWorker('ngsw-worker.js', {
      enabled: true,
      registrationStrategy: 'registerImmediately'
    })
]
};
